import { Playback } from '@iheartradio/web.playback';
import { route } from 'routes-gen';

import {
  type FollowUnfollowContentTypes,
  REG_GATE_TRIGGER_TYPES,
  Routes,
} from '../../utilities/constants';

type FollowUnfollowConfig = {
  formAction: string;
  remixRoute: string;
  regGateTrigger: string;
};

type Props = {
  authorId?: string;
  contentId: string;
  contentType: `${FollowUnfollowContentTypes}`;
};

/**
 * Returns follow/unfollow hook config data for a specific content type.
 *
 * @param {string} authorId User's profile ID (Only needed for Playlists aka Collections)
 * @param {string} contentId ID for the station - i.e. Artist ID, Live station ID, etc.
 * @param {string} contentType The content type of the station - i.e. artist, live, podcast, or playlist
 * @returns {number} Object containing `formAction`, `remixRoute`, and `regGateTrigger` which are content-type specific values to be used in the `use-follow-unfollow.tsx` hook
 */
export const getUseFollowUnfollowConfig = ({
  authorId,
  contentId,
  contentType,
}: Props): FollowUnfollowConfig => {
  switch (contentType) {
    case Playback.StationType.Artist: {
      return {
        formAction: route('/api/v1/artist/:artistId/followers', {
          artistId: String(contentId),
        }),
        remixRoute: route(Routes.Library.Artists),
        regGateTrigger: REG_GATE_TRIGGER_TYPES.ARTIST_STATION_FAVORITE,
      };
    }

    case Playback.StationType.Live: {
      return {
        formAction: route('/api/v1/live/:stationId/followers', {
          stationId: String(contentId),
        }),
        remixRoute: route(Routes.Library.Live),
        regGateTrigger: REG_GATE_TRIGGER_TYPES.LIVE_STATION_FAVORITE,
      };
    }

    case Playback.StationType.Playlist: {
      return {
        formAction: route('/api/v1/collection/:userCollectionId/followers', {
          userCollectionId: `${authorId}-${contentId}`,
        }),
        remixRoute: route(Routes.Library.Playlists),
        regGateTrigger: REG_GATE_TRIGGER_TYPES.PLAYLIST_FAVORITE,
      };
    }

    case Playback.StationType.Podcast: {
      return {
        formAction: route('/api/v1/podcast/:podcastId/followers', {
          podcastId: String(contentId),
        }),
        remixRoute: route(Routes.Library.Podcasts),
        regGateTrigger: REG_GATE_TRIGGER_TYPES.PODCAST_FAVORITE,
      };
    }

    default: {
      return {
        formAction: '',
        remixRoute: '',
        regGateTrigger: '',
      };
    }
  }
};
